<template>
  <section class="custom-dashboard-notifications">
    <div class="mr-2 mt-1 d-flex" style="justify-content: space-between">
      <div>
        <b class="ml-1">{{ component.component_name }}</b>
      </div>
      <div>
        <el-dropdown trigger="click" v-if="!hide_options">
          <el-link
            icon="el-icon-more"
            :underline="false"
            style="transform: rotate(90deg); height: 40px"
          ></el-link>
          <el-dropdown-menu slot="dropdown">
            <a @click="editComponent">
              <el-dropdown-item>Edit</el-dropdown-item>
            </a>
            <a @click="deleteComponent">
              <el-dropdown-item>Delete</el-dropdown-item>
            </a>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="notifications-container" v-if="notificationsData.length">
      <div
        style="max-height: 330px; overflow-x: auto; overflow-y: auto; scrollbar-width:thin;"
      >
        <div class="mb-1 mr-1 ml-1">
          <div
            v-for="(notification, index) in notificationsData"
            :key="index"
            @click="redirect(notification)"
          >
            <el-card
              class="custom-card"
              :style="`margin-bottom: 5px; border-radius: 3px; background-color:${hexToRgb(
                notification.background_color,
                0.1
              )}; border: 0.1px solid ${notification.background_color}`"
            >
              <div class="d-flex">
                <div
                  :style="{
                    '--icon-color': notification.background_color,
                    display: 'flex',
                    'align-items': 'center',
                    padding: '8px',
                    'background-color': hexToRgb(
                      notification.background_color,
                      0.15
                    ),
                    'border-radius': '5px',
                  }"
                  class="notification-icon"
                >
                  <icons :iconName="notification.icon"></icons>
                </div>
                <div class="ml-1">
                  <h4 style="font-size: 14px">{{ notification.heading }}</h4>
                  <p style="font-size: 12px">{{ notification.custom_message }}</p>
                </div>
                <div class="flex-grow-1 text-right">
                  <span class="date-time text-dark fw-medium pl-0 opacity-80">{{
                    notification.updated_at | getFormattedDateTypeThree
                  }}</span>
                </div>
              </div>
            </el-card>
          </div>
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              font-size: 16px;
              font-weight: bold;
            "
            v-if="hasMore"
            @click="loadMore"
          >
            {{ loadMoreText }}
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <el-empty description="No Notifications"></el-empty>
    </div>
  </section>
</template>
<script>
import { postAPICall } from "../../helpers/httpHelper";
import FieldStylesHelper from "../../mixins/FieldStylesHelper";
import { mapGetters } from "vuex";
export default {
  props: ["component", "index", "hide_options"],
  mixins: [FieldStylesHelper],
  data() {
    return {
      page: 1,
      limit: 100,
      notificationsData: [],
      hasMore: false,
      loadMoreText: "Load more...",
    };
  },
  async mounted() {
    await this.getNecessaryInfo();
  },
  computed: {
    ...mapGetters("auth", ["getActiveContactType"]),
  },
  methods: {
    async loadMore() {
      this.loadMoreText = "Loading...";
      this.page++;
      await this.getNecessaryInfo();
      this.loadMoreText = "Load more items...";
    },
    async getNecessaryInfo() {
      if (this.$route.name != "entity-custom-dashboard-config") {
        this.setApplicationUserFilters();
      }
      let responseData = await postAPICall(
        "POST",
        `/entities/notifications?page=${this.page}&limit=${this.limit}`,
        this.component
      );
      this.resetNotificationFilters();
      this.notificationsData = [
        ...this.notificationsData,
        ...responseData.data,
      ];
      this.hasMore = responseData.hasMore;
    },
    editComponent() {
      this.$emit("editComponent", this.index);
    },
    deleteComponent() {
      this.$confirm(
        "Are you sure to delete the Notification component?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.$emit("deleteComponent", this.index);
      });
    },
    redirect(notification) {
      if (
        !["custom-dashboard-config", "entity-custom-dashboard-config"].includes(
          this.$route.name
        )
      ) {
        let routerData;
        if (
          [
            "application-user-dashboard",
            "application-user-page-dashboard",
          ].includes(this.$route.name)
        ) {
          routerData = this.$router.resolve(
            `/ap/entity-view/${notification.entity_id}?key=${Math.floor(
              Math.random() * 100000
            )}&dataId=${notification._id}`
          );
        } else {
          routerData = this.$router.resolve(
            `/entity/view/${notification.entity_id}?dataId=${notification._id}`
          );
        }
        window.open(routerData.href, "_blank");
      }
    },
    setApplicationUserFilters() {
      this.component.notifications.map((notification) => {
        if (notification?.relationship_id && !notification?.relationship_id.includes("@")) {
          notification["customFilters"] = [
            {
              data_type: "RELATION",
              field: notification.relationship_id.includes("#")
                ? "nested#related_to/"
                : "self#related_to",
              operator: "in",
              query_type: "AND",
              nested_related_id: notification.relationship_id.includes("#")
                ? notification.relationship_id.split("#")[0]
                : "",
              relation_entity_id: this.getActiveContactType?.contact_type?._id,
              value: [this.getActiveContactType?.account_data_id],
            },
          ];
        }
      });
    },
    resetNotificationFilters() {
      this.component.notifications = this.component.notifications.map((n) => {
        delete n["customFilters"];
        return n;
      });
    },
  },
};
</script>
<style>
.custom-dashboard-notifications {
  border: 1px solid #eaeaea;
  min-height: 370px !important;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px;
  background-color: #ffffff;
}
.el-card__body,
.el-main {
  padding: 10px;
}
.date-time {
  font-style: normal;
  min-width: 100px;

  @media (max-width: 499.98px) {
    min-width: 70px !important;
  }

  text-align: right;
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 12px;
}
.notification-icon {
  .icon {
    width: 35px;
    height: auto;

    .primary {
      fill: var(--icon-color) !important;
    }
  }
}
.custom-card:hover {
  cursor: pointer;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}
</style>
